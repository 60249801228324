<template>
  <div>
    <Pane />

    <a-card class="container">
      <YearTable
        :year="year"
        :name="user.userName"
        :loading="loading"
        :list="list"
        :disabled="disabled"
        @change="payload => list = payload "
      />

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button type="primary" :disabled="disabled" :loading="saveLoading" @click="save">提交计划</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import YearTable from "./components/year-table.vue";

import { mapState } from "vuex";

import request from "@/api/request";

function add(data) {
  return request({
    url: "/project-service/projectPlan/add",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark],

  components: {
    YearTable
  },

  data() {
    return {
      year: 0,

      loading: false,
      list: [],

      disabled: false,

      saveLoading: false
    };
  },

  computed: {
    ...mapState("auth", ["user"])
  },

  mounted() {
    const now = new Date();
    this.year = now.getFullYear();
  },

  methods: {
    save() {
      if (this.list.length === 0) {
        this.$message.error("请选择项目");
        return;
      }

      let errorFlag = false;

      check: for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        let progress = item.progress || 0;

        if (item.hisList.length === 0) {
          this.$message.error("请添加月份");
          errorFlag = true;
          break check;
        }

        for (let j = 0; j < item.hisList.length; j++) {
          const proportion = item.hisList[j].proportion;
          if (typeof proportion !== "number") {
            this.$message.error("进度请输入数字");
            errorFlag = true;
            break check;
          } else {
            const sum = proportion + progress;
            if (sum > 100) {
              this.$message.error(
                item.projectName + "计划进度加累计项目进度不得超过100%"
              );
              errorFlag = true;
              break check;
            }
          }
        }
      }

      if (errorFlag) {
        return;
      }

      console.log("this.list", this.list);

      this.saveLoading = true;
      add({
        year: this.year,
        type: "projectPlanYear",
        planSubList: this.list
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.center {
  padding: 80px 0;
}
</style>
